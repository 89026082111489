import {useState} from "react";
import { PlusOutlined } from "@ant-design/icons";
import {Button, Col, Form, Modal, Row} from "antd";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import ButtonUpload from "../ButtonUpload";
import {useTranslation} from "react-i18next";
import useMessageUser from "../../../hooks/useMessageUser";

function UploadFileSc() {
  const { md } = useBreakpoint();
  const { t } = useTranslation();
  const { fetchApi, loading }  = useFetch();
  const [openModal, setOpenModal] = useState(false);
  const [ form ] = Form.useForm();
  const { showMessage }  = useMessageUser();

  const onFinish = async (values: any) => {
    const check = await checkUploadFile();
    if (!check) {
      return;
    }
    const formData = new FormData();
    let url = '/members/save_sc';
    Object.keys(values).forEach((index: string) => {
      if (values[index]) {
        formData.set(index, values[index]);
      }
    });

    const dataSend: FetchApiInterface = {
      url,
      data: formData,
      formData: true
    }

    fetchApi(dataSend).then(
      ({data}: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message, 'success');
          form.resetFields();
          setOpenModal(false);
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const checkUploadFile = () => {
    return new Promise((resolve, reject) => {
      const dataSend: FetchApiInterface = {
        url: '/members/can_upload',
      }

      fetchApi(dataSend).then(
        ({ data }: AxiosResponse<ResponseInterface>) => {
          if (data.status === 'success') {
            form.resetFields();
            if (data?.data) {
              resolve(true);
            } else {
              showMessage(data?.message, 'info');
              reject(false);
            }
          } else {
            showMessage(data?.message, 'info');
            reject(false);
          }
        }
      ).catch((reason: any) => {
        showMessage(reason?.message, 'error');
        reject(false);
      });
    });
  }

  return (
    <>
      <Modal footer={[
        <Button key="back" onClick={() => setOpenModal(false)}>
          {t('scUploadFileModalCancel')}
        </Button>
      ]}
       width={800}
       onCancel={() => setOpenModal(false)}
       title={t('scUploadFileModalTitle')} open={openModal}
      >
        <Form form={form} layout="vertical" className="mt-1" onFinish={onFinish}>
          <Row justify='center'>
            <Col className='text-center'>
              <Form.Item
                label={t('scUploadFileModalTitle')}
                name="file"
                valuePropName='file'
                rules={[{ required: true, message: t('codeRequired') }]}
              >
                <ButtonUpload name={t('scUploadFileModalTitleButton')} form={form} fieldName='file' fileType="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" />
              </Form.Item>
              <Button size="large" type="primary" htmlType='submit' className="btn-border-radius" loading={loading}>
                { t('scUploadFileModalSave') }
              </Button>
            </Col>
          </Row>
        </Form>
      </Modal>
      <Button loading={loading} size="large" type="primary" ghost className="btn-border-radius" onClick={() => setOpenModal(true)}>
        <PlusOutlined />{ (!md)? null : ` ${t('scUploadFile')}` }
      </Button>
    </>
  );
}

export default UploadFileSc;
