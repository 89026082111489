import {Button, Pagination, Space, Table, TableProps} from "antd";
import {TableDataInterface} from "../../interfaces/TableData.interface";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import GlobalContex from "../../Store/GlobalContext";

function TableCustom({ dataTable, getRegisters, onPageChange = () => {}, addActions = true }: { dataTable: TableDataInterface, getRegisters: any, onPageChange?: any, addActions?: boolean }) {
  const { dataSource, columns, paginate, actions } = dataTable;
  const { t } = useTranslation();
  const [columnsTrans, setColumnsTrans] = useState<Array<any>>([]);
  const { user: { auth } } = useContext(GlobalContex);
  const onChange: any = (page: number, onSize: number) => {
    onPageChange(page - 1);
  };
  useEffect(() => {
    translateColumns();
  }, [columns]);

  const checkPermission = (roles: Array<string>, actionName: any) => {
    if (!roles.includes("ROLE_USER") && !roles.includes("ROLE_GUEST")) {
      return true;
    }

    const keywords = ["edit", "delete", "update", "remove"];
    return !keywords.some((keyword) => actionName.toLowerCase().includes(keyword));
  };

  const translateColumns = () => {
    const listColumns: Array<any> = [];
    columns.forEach(column => {
      listColumns.push({
        ...column,
        title: t(column.title)
      })
    });
    if (addActions) {
      listColumns.push({
        key: 'actions',
        title: t('scTableActions'),
        render: (_: any, item: any) => {
          return (
            <Space>
              {actions?.map((value, index) => {
                if (checkPermission(auth.roles, value.name)) {
                  return (
                    <Button
                      key={index}
                      type="primary"
                      onClick={() => value.action(item)}
                    >
                      {t(value.name)}
                    </Button>
                  );
                }
                return null;
              })}
            </Space>
          );
        }
      });
    }
    setColumnsTrans(listColumns);
  }
  return(
    <>
      <Table
        dataSource={dataSource}
        columns={columnsTrans}
        pagination={false}
        scroll={{ x: 700 }}
      />
      <br/>
      <Pagination
        total={paginate.totalPages}
        pageSize={paginate.size}
        showTotal={total => <>{t('PaginateTotal')} <strong>{total}</strong> {t('PaginateRegisters')}</>}
        showSizeChanger={false}
        current={paginate.pageNumber}
        defaultPageSize={20}
        defaultCurrent={1}
        onChange={onChange}
      />
    </>
  );
}

export default TableCustom;
