import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.less';
import EcoApp from './EcoApp';
import i18n from "i18next";
import reportWebVitals from './reportWebVitals';
import { initReactI18next } from "react-i18next";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      en: {
        translation: {"menuHome":"Inicio","menuUsers":"Usuarios","menuDevices":"Dispositivos","menuLanguages":"Idiomas","languageCreateBtn":"Crear idioma","languageEditBtn":"Editar idioma","languageDeleteBtn":"Eliminar idioma","languageCreateTitle":"Crear idioma","languageName":"Nombre de idioma","languagePlaceholder":"Ingresar el nombre del nuevo idioma","languagePlantilla":"Descargar plantilla de idiomas","languagePlantillaBtn":"Descargar archivo","languageTraduccion":"Subir traducción","languageTraduccionBtn":"Subir archivo","languageIcon":"Icono del idioma","languageIconBtn":"Subir icono","languageBtn":"Guardar","languageEditPageBtn":"Editar","codeSuccesUpdate":"El registro ha sido actualizado","codeValid":"Por favor verifica los siguientes campos","codeExeptionFile":"Ha ocurrido un error al guardar el archivo o imagen","codeExeptionExtensionFile":"La extensión del archivo es incorrecta","codeExeptionRegisterFound":"El registro ya existe","codeExeptionRegisterIsNotActive":"El registro no se encuentra activado","codeExeptionRegisterNotFound":"El registro no ha sido encontrado","codeExeptionRegisterNotFoundLanguage":"El idioma no existe","codeActiveRegister":"El registro ha sido activado","codeDesactiveRegister":"El registro ha sido desactivado","codeEmptyFile":"El campo * está vacío","codeRequired":"El campo es obligatorio","codeSize30":"El campo no puede tener más de 30 caracteres","codeLanRegister":"El idioma * ya se encuentra registrado"}
      }
    },
    lng: "en", // if you're using a language detector, do not define the lng option
    fallbackLng: "en",

    interpolation: {
      escapeValue: false // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    }
  });

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <EcoApp />
);

reportWebVitals();
