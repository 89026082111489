import {BrowserRouter as Router, Route, Routes, Navigate} from "react-router-dom";
import GlobalContex from "./Store/GlobalContext";
import Auth from "./Store/Auth";
import NavBar from "./Store/NavBar";
import Language from "./Pages/Private/Language";
import Catalog from "./Pages/Private/Catalog";
import Device from "./Pages/Private/Device";
import LoginPage from "./Pages/Public/LoginPage";
import Home from "./Pages/Private/Home";
import Users from "./Pages/Private/Users";
import Profile from "./Pages/Private/Profile";
import CurrentLanguage from "./Store/CurrentLanguage";
import LayoutPublic from "./Layouts/Layout.public";
import LayoutPrivate from "./Layouts/Layout.private";
import UpdateLanguages from "./Store/UpdateLanguages";
import Prospects from "./Pages/Private/Prospects";
import DoublePage from "./Pages/Private/DoublePage";
import PossibleAffiliate from "./Pages/Private/PossibleAffiliate";
import PowerBI from "./Pages/Private/PowerBI";

function EcoApp() {

  const { auth, setAuth } = Auth();
  const { nav, setNav } = NavBar();
  const { language, setLanguage } = CurrentLanguage();
  const { updateLis, setUpdateLis } = UpdateLanguages();

  const contextInit = {
    user: {
      auth, setAuth
    },
    navBar: {
      nav, setNav
    },
    currentLanguage: {
      language, setLanguage
    },
    updateListLanguages: {
      updateLis,
      setUpdateLis,
    }
  }

  return (
    <GlobalContex.Provider value={contextInit}>
      <Router>
        <Routes>
          <Route path="/authorization-code/*" element={<LayoutPublic children={<LoginPage/>}/>}/>
          <Route path="/power-report" element={<LayoutPrivate children={<PowerBI/>}/>} />
          <Route path="/languages" element={<LayoutPrivate children={<Language/>}/>} />
          <Route path="/catalog" element={<LayoutPrivate children={<Catalog/>}/>} />
          <Route path="/device" element={<LayoutPrivate children={<Device/>}/>} />
          <Route path="/home" element={<LayoutPrivate children={<Home/>}/>} />
          <Route path="/double_affiliate" element={<LayoutPrivate children={<DoublePage/>}/>} />
          <Route path="/possible_affiliate" element={<LayoutPrivate children={<PossibleAffiliate/>}/>} />
          <Route path="/prospects" element={<LayoutPrivate children={<Prospects/>}/>} />
          <Route path="/users" element={<LayoutPrivate children={<Users/>}/>} />
          <Route path="/profile" element={<LayoutPrivate children={<Profile/>}/>} />
          <Route path="/" element={<Navigate replace to="/authorization-code" />} />
        </Routes>
      </Router>
    </GlobalContex.Provider>
  );
}

export default EcoApp;
