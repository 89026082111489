import {Button, Card, Col, Form, Input, message, notification, Popconfirm, Row, Select, Switch, Typography} from "antd";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {useContext, useEffect, useState} from "react";
import {DpaInterface} from "../../../interfaces/DpaInterface";
import useMessageUser from "../../../hooks/useMessageUser";
import {useTranslation} from "react-i18next";
import DpaSelected from "../DpaSelected";
import GlobalContex from "../../../Store/GlobalContext";

const { Option } = Select;
const { Paragraph } = Typography;

function FormUser({ data, getRegisters }: { data?: any, getRegisters: any}) {
  const [form] = Form.useForm();
  const { fetchApi } = useFetch();
  const [dataEdit, setDataEdit] = useState<boolean>(false);
  const [roles, setRoles] = useState<Array<any>>([]);
  const { showMessage }  = useMessageUser();
  const { t } = useTranslation();
  const [filterApply, setFilterApply] = useState<any>({});
  const [roleSelected, setRoleSelected] = useState<string | null>(null);
  const [endLevel, setEndLevel] = useState<boolean>(false);
  const { user: { auth } } = useContext(GlobalContex);

  useEffect(() => {
    getRoles();
    if(Object.keys(data).length) {
      setDataEdit(true);
      form.setFieldsValue({
        ...data,
        statusRegister: true,
        role: data?.roles[0]?.rolId,
      });
      console.log(data);
    }
  }, []);

  const onFinish = (values: any) => {
    let dataConfig: any = {
      "useName": values.useName?.trim(),
      "useLastName": values.useLastName?.trim(),
      "useEmail": values.useEmail?.trim(),
      "rolId": values.role,
      "statusRegister": values.statusRegister,
    }
    if (values?.dpa) {
      const dpa = JSON.parse(values?.dpa);
      console.log('dpa seleccionado: ', dpa);
      dataConfig.dpaId = dpa?.dpaId;
    }
    if (auth?.roles.includes('ROLE_COUNTRY_ADMIN')) {
      dataConfig.dpaId = auth?.user?.dpa?.dpaIdCountry;
    }
    if (dataEdit) {
      dataConfig.usuId = data.useId;
    }
    const dataSend: FetchApiInterface = {
      url: (!dataEdit) ? '/users/save_user' : `/users/update_user`,
      data: dataConfig
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          showMessage(data?.message);
          getRegisters();
        } else {
          console.log(data);
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      if (reason?.data) {
        const errorFields = Object.entries(reason?.data || {}).map(([name, errors]) => ({
          name,
          errors: Array.isArray(errors) ? errors : [errors],
        }));

        // Establecer los errores en el formulario
        form.setFields(errorFields);
      }
      showMessage(reason?.message, 'error');
    });
  }

  const getRoles = () => {
    const dataSend: FetchApiInterface = {
      url: `/users/rol`,
      data
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          if (auth?.roles[0] === 'ROLE_SYSADMIN' || auth?.roles[0] === 'ROLE_GLOBAL_ADMIN') {
            setRoles(data?.data);
          }
          if (auth?.roles[0] === 'ROLE_REGION_ADMIN') {
            const filteredRoles = data?.data?.filter((role: any) => {
              const roleNamesToInclude = [
                'ROLE_COUNTRY_ADMIN',
                'ROLE_USER',
                'ROLE_GUEST',
                'ROLE_COUNTRY_PROCESS'
              ];
              return roleNamesToInclude.includes(role.rolName);
            });
            setRoles(filteredRoles);
          }
          if (auth?.roles[0] === 'ROLE_COUNTRY_ADMIN') {
            const filteredRoles = data?.data?.filter((role: any) => {
              const roleNamesToInclude = [
                'ROLE_COUNTRY_PROCESS',
                'ROLE_USER',
                'ROLE_GUEST',
              ];
              return roleNamesToInclude.includes(role.rolName);
            });
            setRoles(filteredRoles);
          }
        } else {
          showMessage(data?.message, 'info');
        }
      }
    ).catch((reason: any) => {
      showMessage(reason?.message, 'error');
    });
  }

  const selectedRole = (value: any) => {
    const item = roles.find(value1 => (value1.rolId === value));
    form.resetFields(['dpa']);
    setFilterApply({});
    setRoleSelected(item?.rolName);
    /*const levels = {
      ROLE_SYSADMIN: 1,
      ROLE_GLOBAL_ADMIN: 1,
      ROLE_REGION_ADMIN: 3,
      ROLE_COUNTRY_ADMIN: 2,
      ROLE_USER: 2,
      ROLE_GUEST: null,
      ROLE_COUNTRY_PROCESS: 2,
    }*/
  }

  const submitUpdateUser = () => {
    form.submit();
  }

  return (
    <Row justify='center'>
      <Col span={24} md={20}>
        <Form
          form={form}
          name="deviceForm"
          autoComplete="off"
          layout='vertical'
          onFinish={onFinish}
        >
          {/*{
            (dataEdit && !addCommunities) && (
              <div className='text-right mb-2'>
                <Button type='primary' onClick={() => setAddCommunities(true)}>{t('btnAddCommunities')}</Button>
              </div>
            )
          }*/}

          <Col span={24}>
            <Form.Item
              label={t('role')}
              name="role"
            >
              <Select
                placeholder={t('roleLabel')}
                allowClear
                showSearch
                onChange={selectedRole}
              >
                {
                  roles.map(region => (
                    <Option key={region.rolId} value={region.rolId}>{ region.rolLabel }</Option>
                  ))
                }
              </Select>
            </Form.Item>
          </Col>

          {
            (roleSelected && !auth?.roles.includes('ROLE_COUNTRY_ADMIN')) && (
              <Row gutter={[10, 0]}>
                <Col span={24}>
                  {
                    (roleSelected === 'ROLE_COUNTRY_ADMIN' || roleSelected === 'ROLE_GUEST' || roleSelected === 'ROLE_USER' || roleSelected === 'ROLE_COUNTRY_PROCESS') && (
                      <DpaSelected form={form} setEndLevel={setEndLevel} setFilterApply={setFilterApply} filterApply={filterApply} maxLevel={ 2 } />
                    )
                  }
                  {
                    (roleSelected === 'ROLE_REGION_ADMIN') && (
                      <DpaSelected form={form} setEndLevel={setEndLevel} setFilterApply={setFilterApply} filterApply={filterApply} maxLevel={ 1 } />
                    )
                  }
                </Col>
              </Row>
            )
          }

          <Row gutter={[10, 0]}>
            <Col span={24} md={12}>
              <Form.Item
                label={t('useName')}
                name="useName"
                rules={[{ required: true, message: t('codeRequired') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('useLastName')}
                name="useLastName"
                rules={[{ required: true, message: t('codeRequired') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('useEmail')}
                name="useEmail"
                rules={[{ required: true, message: t('codeRequired') }]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col span={24} md={12}>
              <Form.Item
                label={t('statusRegister')}
                name="statusRegister"
                valuePropName='checked'
                initialValue={true}
              >
                <Switch/>
              </Form.Item>
            </Col>
          </Row>

          <Form.Item>
            {
              (dataEdit) ? (
                  <Popconfirm
                    title={t('btnUpdateUserAction')}
                    okText={t('btnYes')}
                    cancelText={t('btnNo')}
                    onConfirm={submitUpdateUser}
                  >
                    <Button type='primary' htmlType='button'>{t('btnSave')}</Button>
                  </Popconfirm>
                ) : (
                <Button type='primary' htmlType='submit'>{t('btnSave')}</Button>
              )
            }
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default FormUser;
