import {Avatar, Card, Col, message, Row, Space, Typography} from "antd";
import {UserOutlined, CameraOutlined} from "@ant-design/icons";
import {FetchApiInterface} from "../../../interfaces/FetchApi.interface";
import {AxiosResponse} from "axios";
import {ResponseInterface} from "../../../interfaces/Response.interface";
import useFetch from "../../../hooks/useFetch";
import {useEffect, useState} from "react";
import ScDpaList from "../../../interfaces/scDpaList";
import {useTranslation} from "react-i18next";
import ImageWithAuth from "../ImageAuth";

const { Title, Text } = Typography;

function NewScDetail({ proId }: { proId: number }) {

  const { fetchApi } = useFetch();
  const [scDetail, setScDetail] = useState<any>();
  const { t } = useTranslation();

  useEffect(() => {
    getScById();
  }, [proId]);

  const getScById = () => {
    const dataSend: FetchApiInterface = {
      url: `/members/prospect/find_propects/${proId}`,
    }
    fetchApi(dataSend ).then(
      ({ data }: AxiosResponse<ResponseInterface>) => {
        if (data.status === 'success') {
          const dataResponse = data?.data;
          setScDetail(dataResponse);
        } else {
          message.warning(data.message);
        }
      }
    ).catch((reason: any) => {
      const msg = (reason?.message && reason.message.includes('code_')) ? reason?.message : 'Server error.';
      message.error(msg);
    });
  }

  return (
    <Row>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><UserOutlined /> {t('scNewDetailTitle')}</Title>
          <Row className='mt-1' gutter={20}>
            <Col span={24} md={8} lg={6} xxl={4} className='text-center'>
              <Space direction='vertical' align='center'>
                <Avatar size={60} className='bg-primary'>
                  { scDetail?.proName?.charAt(0) }
                </Avatar>
                <Text strong>
                  { scDetail?.proName }
                </Text>
              </Space>
            </Col>
            <Col span={24} md={16} lg={18} xxl={20}>
              <Row gutter={[40, 20]}>
                <Col span={24} md={12}>
                  <Row justify='space-between' className='mb-1'>
                    <Col flex='auto'>
                      {t('scNewDetailGenre')}
                    </Col>
                    <Col>
                      <Text strong>{ scDetail?.proGenre }</Text>
                    </Col>
                  </Row>
                </Col>
                <Col span={24} md={12} className='pl-2'>
                  <ScDpaList dpa={scDetail?.dpa || null} />
                </Col>
              </Row>
            </Col>
          </Row>
        </Card>
      </Col>

      <Col span={24}>
        <Card className='border-radius'>
          <Title level={3}><CameraOutlined /> {t('fiscalYearName')}</Title>
          <Row className='mt-1' gutter={[30,30]}>
            <Col span={24} md={12}>
              <Title level={4}>{t('scPhotoActualNewProspect')}</Title>
              <ImageWithAuth src={scDetail?.proChildPhotoActual || ''} scId={scDetail?.proId || 0} tag='prospect' />
            </Col>
            <Col span={24} md={12}>
              <Title level={4}>{t('scPhotoActualNewFamilyProspect')}</Title>
              <ImageWithAuth src={scDetail?.proFamilyPhotoActual || ''} scId={scDetail?.proId || 0} tag='prospect' />
            </Col>
          </Row>
        </Card>
      </Col>

    </Row>
  );
}

export default NewScDetail;
