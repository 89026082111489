import {Avatar, Button, Col, Drawer, Dropdown, Menu, Row, Space} from "antd";
import { UserOutlined, MenuOutlined } from '@ant-design/icons';
import Logo from "../../assets/images/logo.svg";
import {useTranslation} from "react-i18next";
import ChangeLanguage from "./ChangeLanguage";
import {Link, useLocation, useNavigate} from "react-router-dom";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import {useEffect, useState} from "react";
import {USER_INFO} from "../../config/config";
import {closeSession} from "../../config/interceptor";

const menulist = [
  {
    name: 'menuHome',
    route: '/home'
  },
  {
    name: 'menuProspects',
    route: '/prospects'
  },
  {
    name: 'doubleAffiliate',
    route: '/double_affiliate'
  },
  {
    name: 'possibleAffiliate',
    route: '/possible_affiliate'
  },
  {
    name: 'menuUsers',
    route: '/users'
  },
  {
    name: 'menuDevices',
    route: '/device'
  },
  {
    name: 'menuLanguages',
    route: '/languages'
  },
  {
    name: 'menuCatalogs',
    route: '/catalog'
  },
  {
    name: 'menuBI',
    route: '/power-report'
  }
];
const ROLE_SYSADMIN = [
  {
    name: 'menuUsers',
    route: '/users'
  },
  {
    name: 'menuDevices',
    route: '/device'
  },
  {
    name: 'menuLanguages',
    route: '/languages'
  },
  {
    name: 'menuCatalogs',
    route: '/catalog'
  },
  {
    name: 'menuBI',
    route: '/power-report'
  }
];

const ROLE_USER = [
  {
    name: 'menuHome',
    route: '/home'
  },
  {
    name: 'menuProspects',
    route: '/prospects'
  },
  {
    name: 'menuUsers',
    route: '/users'
  },
  {
    name: 'menuCatalogs',
    route: '/catalog'
  }
];
const ROLE_GUEST = [
  {
    name: 'menuHome',
    route: '/home'
  },
  {
    name: 'menuProspects',
    route: '/prospects'
  },
];

function HeaderLayout() {

  const navigation = useNavigate();
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const { xl } = useBreakpoint();
  const loca = useLocation();
  const [user, setUser] = useState<any>();

  useEffect(() => {
    if(localStorage.getItem(USER_INFO)) {
      setUser(JSON.parse(localStorage.getItem(USER_INFO) as string));
    }
  }, []);

  const goRoute = (item: any) => {
    if (item.route !== '/home') {
      localStorage.removeItem('filter-home');
    }
    navigation(item.route);
  }

  const logout = () => {
    closeSession();
  }

  const getMenu = (): Array<any> => {
    if (user?.roles.includes('ROLE_USER') ) {
      return ROLE_USER;
    }
    if (user?.roles.includes('ROLE_GUEST')) {
      return ROLE_GUEST;
    }
    if (user?.roles.includes('ROLE_SYSADMIN')) {
      return ROLE_SYSADMIN;
    }
    return menulist;
  }

  const menu = (
    <Menu
      items={[
        {
          key: '1',
          label: (
            <>
              { user?.user?.useName } { user?.user?.useLastName }
            </>
          ),
        },
        {
          key: '2',
          label: (
            <Link to="/profile">
              {t('myProfile')}
            </Link>
            )
        },
        {
          key: '3',
          label: (
            <Button danger type='link' onClick={logout}>
              {t('closeSession')}
            </Button>
          )
        }
      ]}
    />
  );

  return (
    <header className="h-100">
      <Row align="middle" className="h-100">
        <Col flex="auto">
          <Row align="middle" gutter={[5, 10]}>
            <Col>
              <img height={60} src={Logo} alt="SMP" style={{ marginRight: '20px' }}/>
            </Col>
            {
              (xl) ? (
                <>
                  {
                    getMenu().map((value) => (
                      <Col key={value.name}>
                        <Button type="text" className={`btn-border-radius ${(loca.pathname === value.route) ? 'active-menu-item' : ''}`} onClick={() => goRoute(value)}>{t(value.name)}</Button>
                      </Col>
                    ))
                  }
                </>
              ) : (
                <>
                  <Button type="primary" icon={<MenuOutlined />} onClick={() => setOpen(true)} ghost />
                  <Drawer title="Menú" placement="right" onClose={() => setOpen(false)} open={open}>
                    <Row>
                      {
                        getMenu().map((value) => (
                          <Col span={24} key={value.name} className="px-1">
                            <Button type="text" className='btn-border-radius' onClick={() => goRoute(value)}>{t(value.name)}</Button>
                          </Col>
                        ))
                      }
                    </Row>
                  </Drawer>
                </>
              )
            }
          </Row>
        </Col>
        <Col>
          <Space align="center">
            <ChangeLanguage />
            <Dropdown overlay={menu}>
              <Avatar size={50} icon={<UserOutlined />} />
            </Dropdown>
          </Space>
        </Col>
      </Row>
    </header>
  );
}

export default HeaderLayout;
